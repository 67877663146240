import React from "react";
import { Link } from 'react-router-dom';
import "./App.css";

function DochubCard({ cardname, cardcover, cardvolume, cardid }) {
  return (
    <Link className="Doccardwrapper" to={`/doc?id=${cardid}`}>
    <div >      
      <img src={`http://api.lore-stone.com${cardcover}`} alt={`${cardname} cover`} />
      <p>{cardvolume}</p>
    </div>
    </Link>
  );
}

export default DochubCard;

