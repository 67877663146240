import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import Login from "./login";
import Register from "./register";
import DocHub from "./dochub";
import Doc from "./doc";
import ProtectedRoute from "./ProtectedRoute";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected Routes */}
        <Route
          path="/dochub"
          element={
            <ProtectedRoute>
              <DocHub />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doc"
          element={
            <ProtectedRoute>
              <Doc />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
