import React, { useEffect, useState } from "react";

import "./App.css";

function Comment({avatar, username, commentText}) {
  return (
    <div className="commentcontainer">
        <div className="commentpointer"></div>
      <div className="commentuserwrapper">
        <img src={`${avatar}`} className="commentavatar" alt="avatar" />
        <p className="commentusername">{username}</p>
      </div>
      <div className="commentwrapper">
        <p>{commentText}</p>
      </div>
    </div>
  );
}

export default Comment;
