import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import "./App.css";

function CreatePlacesPopup({ setIsActive, refresh }) {
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("id"); // Extract parentId from the URL

  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });

  const [error, setError] = useState(""); // To display validation or API errors

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    // Basic validation
    if (!formData.title || !formData.content) {
      setError("Title and content are required.");
      return;
    }

    try {
      const response = await axios.post(
        `http://api.lore-stone.com/api/volumes/${parentId}/places`,
        formData
      );
      console.log("Place added successfully:", response.data);
      refresh(); // Refresh the parent state to reflect the new place
      setIsActive(false); // Close the popup
    
    } catch (err) {
      console.error("Error adding place:", err);
      setError("Failed to save the place. Please try again.");
    }
  };

  return (
    <div className="charactercontainer">
      <div className="characterwrapper">
        <p>Place Title</p>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Place Title"
        />
        <p>Place Content</p>
        <textarea
          name="content"
          value={formData.content}
          onChange={handleInputChange}
          placeholder="Place Content"
        ></textarea>
        {error && <p className="error">{error}</p>}
        <div className="characterbuttons">
          <button onClick={() => setIsActive(false)}>Cancel</button>
          <button onClick={handleSubmit}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default CreatePlacesPopup;
