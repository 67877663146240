import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import "./App.css";

function CharacterPopup({ setIsActive, characterData, indexId, refresh }) {
  const [searchParams] = useSearchParams();
  const parentId = searchParams.get("id"); // Extract parentId from the URL
    console.log(characterData);
  const [formData, setFormData] = useState({
    name: characterData?.name || "",
    bio: characterData?.bio || "",
    characterclass: characterData?.characterclass || "",
    race: characterData?.race || "",
    level: characterData?.level || "",
    age: characterData?.age || "",
    gender: characterData?.gender || "",
    eyecolor: characterData?.eyecolor || "",
    haircolor: characterData?.haircolor || "",
    height: characterData?.height || "",
    weight: characterData?.weight || "",
    sexualorientation: characterData?.sexualorientation || "",
    stats: characterData?.stats || "",
  });

  const [image, setImage] = useState(null); // To handle image file
  const [error, setError] = useState(""); // To show any errors during submission

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };



  

  const handleSubmit = async () => {
    if (!formData.name || !formData.bio) {
      setError("Name and Bio are required!");
      return;
    }

    const characterData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      characterData.append(key, value);
    });

    if (image) {
      characterData.append("image", image);
    }

    try {
      const response = await axios.put(
        `http://api.lore-stone.com/api/volumes/${parentId}/characters/${indexId}`,
        characterData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("Character updated:", response.data);
      refresh();
    //   setIsActive(false); // Close the popup after successful submission
    } catch (err) {
      console.error("Error updating character:", err);
      setError("Failed to update character. Please try again.");
    }
  };

  return (
    <div className="charactercontainer">
      <div className="characterwrapper">
        <div className="characterheaderinfo">
        <img className="characterpopupimage" src={`http://api.lore-stone.com${characterData?.image}`} />
        <h3>{characterData?.name}</h3>
        </div>
        <p>Character Image</p>
        <input type="file" onChange={handleFileChange} />
        <p>Character Name</p>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Character Name"
        />
        <p>Character Bio</p>
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleInputChange}
          placeholder="Character Bio"
        ></textarea>
        <div className="characterinnerwrapper">
          <div className="characterinnerwrapper2">
            <p>Character Class</p>
            <input
              type="text"
              name="characterclass"
              value={formData.characterclass}
              onChange={handleInputChange}
              placeholder="Character Class"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Race</p>
            <input
              type="text"
              name="race"
              value={formData.race}
              onChange={handleInputChange}
              placeholder="Character Race"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Level</p>
            <input
              type="number"
              name="level"
              value={formData.level}
              onChange={handleInputChange}
              placeholder="Character Level"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Age</p>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              placeholder="Character Age"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Gender</p>
            <input
              type="text"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              placeholder="Character Gender"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Eye Color</p>
            <input
              type="text"
              name="eyecolor"
              value={formData.eyecolor}
              onChange={handleInputChange}
              placeholder="Character Eye Color"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Hair Color</p>
            <input
              type="text"
              name="haircolor"
              value={formData.haircolor}
              onChange={handleInputChange}
              placeholder="Character Hair Color"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Height</p>
            <input
              type="text"
              name="height"
              value={formData.height}
              onChange={handleInputChange}
              placeholder="Character Height"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Weight</p>
            <input
              type="text"
              name="weight"
              value={formData.weight}
              onChange={handleInputChange}
              placeholder="Character Weight"
            />
          </div>
          <div className="characterinnerwrapper2">
            <p>Character Sexual Orientation</p>
            <input
              type="text"
              name="sexualorientation"
              value={formData.sexualorientation}
              onChange={handleInputChange}
              placeholder="Character Sexual Orientation"
            />
          </div>
        </div>
        <p>Character Stats</p>
        <textarea
          name="stats"
          value={formData.stats}
          onChange={handleInputChange}
          placeholder="Character Stats"
        ></textarea>
        {error && <p className="error">{error}</p>}
        <div className="characterbuttons">
          <button onClick={() => setIsActive(false)}>Cancel</button>
          <button onClick={handleSubmit}>Update</button>
        </div>
      </div>
    </div>
  );
}

export default CharacterPopup;
