import React, { useState, useEffect } from "react";
import axios from "axios";

import Docheader from "./docheader";
import DochubCard from "./dochubcard";
import Doccomp from "./doccomp";
import "./App.css";

function Dochub() {
  const [docs, setDocs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const fetchDocs = async () => {
    try {
      const response = await axios.get("http://api.lore-stone.com/api/docs");
      setDocs(response.data);
      console.log(response.data);
    } catch (err) {
      console.error("Error fetching documents:", err);
    }
  };

  const handleCreateDoc = (success) => {
    if (success) fetchDocs(); // Refresh the document list on success
  };

  useEffect(() => {
    fetchDocs(); // Fetch documents on component mount
  }, []);

  return (
    <div className="Docwrapper">
      <Docheader />

      <div className="dochubcontainer">
        <div className="dochubsidebar">
          <button onClick={() => setShowPopup(true)}>Create Document</button>
        </div>
        <div className="dochubcontent">
          {docs.map((doc) => (
            <DochubCard
              key={doc._id}
              cardname={doc.docname}
              cardcover={`${doc.cover}`}
              cardvolume={doc.volume}
              cardid={doc._id}
            />
          ))}
        </div>
      </div>

      {showPopup && (
        <Doccomp onClose={() => setShowPopup(false)} onCreate={handleCreateDoc} />
      )}
    </div>
  );
}

export default Dochub;
