import React, { useRef, useState } from "react";
import axios from "axios";
import logo from "./assets/logo.png";
import "./App.css";

function Register() {
  const fileInputRef = useRef(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(file);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    // Validate fields
    if (!username || !password || !avatar) {
      setMessage("All fields are required!");
      return;
    }

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("avatar", avatar);

    try {
      const response = await axios.post("http://api.lore-stone.com/api/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message || "Registration successful!");
    } catch (error) {
      setMessage(error.response?.data?.error || "Error during registration.");
    }
  };

  return (
    <div className="wrapper">
      <div className="appcontainer">
        <img src={logo} className="logo" alt="logo" />
        <h1>Register</h1>
        <form className="inputcontainer" onSubmit={handleRegister}>
          <div className="file-input-wrapper">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              id="fileInput"
              style={{ display: "none" }}
            />
            <label
              htmlFor="fileInput"
              className="file-input-label"
              onClick={() => fileInputRef.current.click()}
            >
              Choose Avatar
            </label>
            {avatar && <p>{avatar.name}</p>}
          </div>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Register</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default Register;
