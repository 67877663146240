import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming React Router is used
import axios from "axios";
import logo from "./assets/logo.png";
import "./App.css";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    localStorage.setItem("token", "");
    if (!username || !password) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post("http://api.lore-stone.com/api/login", {
        username,
        password,
      });
    
      // Save token to local storage (or session storage)
      localStorage.setItem("token", response.data.token);
    
      // Redirect to /dochub
      navigate("/dochub");
    } catch (err) {
      setError(err.response?.data?.error || "Login failed. Please try again.");
    }
  };

  return (
    <div className="wrapper">
      <div className="appcontainer">
        <img src={logo} className="logo" alt="logo" />
        <h1>Log In</h1>
        <form className="inputcontainer" onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Log In</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default Login;
