import React from "react";
import { Link } from 'react-router-dom';
import logo from "./assets/logo.png";
import "./App.css";

function Home() {
  return (
    <div className="wrapper">
      <div className="appcontainer">
        <img src={logo} className="logo" alt="logo" />
        <h1>POrtal

        </h1>
        <div className="inputcontainer">
         <Link className="button"  to="/login"> Login</Link>

        </div>
      </div>
    </div>
  );
}

export default Home;