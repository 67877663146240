import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./assets/logo.png";
import "./App.css";

function Docminiheader({docname, doccover}) {
  const [avatar, setAvatar] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const fetchUserDetailsFromToken = () => {
     
      try {
        // Retrieve token from localStorage
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        // Decode the token payload
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));

        // Extract username and avatar from the token payload
        setUsername(tokenPayload.username);
        setAvatar(tokenPayload.avatar);
      } catch (err) {
        console.error("Error decoding token:", err);
      }
    };

    fetchUserDetailsFromToken();
  }, []);

  return (
    <div className="Docheadercontainer">
     <Link to="/dochub"> <img src={logo} className="doclogo" alt="logo" />  </Link>
      <h1 className="doculogo"><img src={`http://api.lore-stone.com${doccover}`} className="doccover" alt="logo" /> {`${docname}`}</h1>
      <div className="userbox">
        <img src={`http://api.lore-stone.com${avatar}`} className="avatar" alt="User Avatar" />
        <h2>{username}</h2>
      </div>
    </div>
  );
}

export default Docminiheader;
