import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "./App.css";

function NotePopup({ setIsActive, refresh, noteData, indexId }) {
    const [searchParams] = useSearchParams();
    const parentId = searchParams.get("id"); 
  const [formData, setFormData] = useState({
    title: noteData?.title || "",
    content: noteData?.content || "",
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (noteData) {
      setFormData({
        title: noteData.title,
        content: noteData.content,
      });
    }
  }, [noteData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    if (!formData.title || !formData.content) {
      setError("Title and Content are required!");
      return;
    }

    try {
      const response = await axios.put(
        `http://api.lore-stone.com/api/volumes/${parentId}/notes/${indexId}`,
        formData
      );
      console.log("Note updated:", response.data);
      refresh(); // Refresh volume data
      setIsActive(false); // Close the popup
    } catch (err) {
      console.error("Error updating note:", err);
      setError("Failed to update note. Please try again.");
    }
  };

  return (
    <div className="charactercontainer">
      <div className="characterwrapper">
        <p>Note Title</p>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleInputChange}
          placeholder="Note Title"
        />
        <p>Note Info</p>
        <textarea
          name="content"
          value={formData.content}
          onChange={handleInputChange}
          placeholder="Note Info"
        ></textarea>

        {error && <p className="error">{error}</p>}
        <div className="characterbuttons">
          <button onClick={() => setIsActive(false)}>Cancel</button>
          <button onClick={handleUpdate}>Update</button>
        </div>
      </div>
    </div>
  );
}

export default NotePopup;
