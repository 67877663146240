import React, { useState } from "react";
import axios from "axios";
import "./App.css";

function Doccomp({ onClose, onCreate }) {
  const [docname, setDocname] = useState("");
  const [volume, setVolume] = useState("");
 const [cover, setCover] = useState(null);
//   const handleSave = async () => {
//     if (!docname || !volume || !cover) {
//         alert("All fields are required!");
//         return;
//     }

//     if (!(cover instanceof File)) {
//         console.error("Cover is not a valid file object:", cover);
//         return;
//     }

//     console.log("Saving document with:", { docname, volume, cover });
    
    // const formData = new FormData();
    // formData.append("docname", docname);
    // formData.append("volume", volume); 
    // formData.append("cover", cover);

    // axios.post("/api/docs", formData, {
    //     headers: {
    //         "Content-Type": "multipart/form-data",
    //         Accept: "application/json",
    //     },
    // })
    // .then((response) => {
    //     console.log("Document created:", response.data);
    //     if (response.status === 201) {
    //         onCreate(true); // Notify parent of successful creation
    //         onClose(); // Close the popup
    //     }
    // })
    // .catch((err) => {
    //     console.error("Error creating document:", err);
    //     if (err.response) {
    //         console.error("Error from server:", err.response.data);
    //     } else {
    //         console.error("Client-side error or network issue:", err.message);
    //     }
    //     onCreate(false); // Notify parent of failure
    // });
//};
    const handleSave = async () => {
        if (!docname || !volume || !cover) {
            alert("All fields are required!");
            return;
        }
    
        // Convert the file to Base64
        const fileToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        };
    
        try {
            const base64Cover = await fileToBase64(cover);
    
            const payload = {
                docname,
                volume,
                cover: base64Cover, // Send the file as a Base64 string
            };
    
            const response = await axios.post("http://api.lore-stone.com/api/docs", payload, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
    
            console.log("Document created:", response.data);
            if (response.status === 201) {
                onCreate(true); // Notify parent of successful creation
                onClose(); // Close the popup
            }
        } catch (err) {
            console.error("Error creating document:", err);
            if (err.response) {
                console.error("Error from server:", err.response.data);
            } else {
                console.error("Client-side error or network issue:", err.message);
            }
            onCreate(false); // Notify parent of failure
        }
    };
    


  
  
  return (
    <div className="popupwrapper">
      <div className="popup">
        <div className="popupheader">
          <h2>Create Document</h2>
        </div>
        <div className="popupcontent">
          <input
            type="text"
            placeholder="Document Name"
            value={docname}
            onChange={(e) => setDocname(e.target.value)}
          />
          <input
            type="file"
            className="file-input"
            onChange={(e) => setCover(e.target.files[0])}
          />
          <input
            type="text"
            placeholder="Document Volume"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
        </div>
        <div className="popupfooter">
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default Doccomp;
