import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Docminiheader from "./docminiheader";
// Basic Text Editing Icons
import Undo from "@mui/icons-material/Undo";
import Redo from "@mui/icons-material/Redo";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import StrikethroughS from "@mui/icons-material/StrikethroughS";
import DeleteIcon from "@mui/icons-material/Delete";


// Text Alignment Icons
import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenter from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRight from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustify from "@mui/icons-material/FormatAlignJustify";

// Additional Rich Text Options
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import FormatListNumbered from "@mui/icons-material/FormatListNumbered";
import Link from "@mui/icons-material/Link";
import LinkOff from "@mui/icons-material/LinkOff";
import Image from "@mui/icons-material/Image";
import FormatColorText from "@mui/icons-material/FormatColorText";
import Highlight from "@mui/icons-material/Highlight";

//sidebar
import Description from "@mui/icons-material/Description";
import Person from "@mui/icons-material/Person";
import Place from "@mui/icons-material/Place";
import Notes from "@mui/icons-material/Notes";
import Comment from "./comment";
import CreateCharacterPopup from "./createcharacterpopup";
import CreatePlacesPopup from "./createplacespopup";
import CreateNotesPopup from "./createnotespopup";
import CharacterPopup from "./characterpopup";
import PlacesPopup from "./placepopup";
import NotePopup from "./notepopup";

function Doc() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [content, setContent] = useState("");
  const [avatar, setAvatar] = useState("");
  const [username, setUsername] = useState("");
  const [docname, setDocname] = useState("");
  const [doccover, setDoccover] = useState("");
  const [showFontColorPicker, setShowFontColorPicker] = useState(false);
  const [showHighlightColorPicker, setShowHighlightColorPicker] =
    useState(false);
  const [wordCount, setWordCount] = useState({ total: 0, selected: 0 });

  const editorRef = useRef(null); // Reference to contentEditable div
  const [showcharacterpopup, setshowcharacterpopup] = useState(false);
  const [showplacespopup, setshowplacespopup] = useState(false);
  const [shownotespopup, setshownotespopup] = useState(false);
  const [characterinfo, setcharacterinfo] = useState(null);
  const [placeinfo, setplaceinfo] = useState(null);
  const [noteinfo, setnoteinfo] = useState(null);
  const [showcharacterinfopopup, setshowcharacterinfopopup] = useState(false);
  const [showplacesinfopopup, setshowplacesinfopopup] = useState(false);
  const [shownotesinfopopup, setshownotesinfopopup] = useState(false);
  const [characterinfoindexpopup, setshowcharacterinfoindexpopup] = useState(null);
  const [placesinfoindexpopup, setshowplacesinfoindexpopup] = useState(null);
  const [notesinfoindexpopup, setshownotesinfoindexpopup] = useState(null);
  const [volume, setVolume] = useState(null);

  const fetchVolume = async () => {
    try {
      if (!id) {
        console.error("Parent ID is missing from the URL.");
        return;
      }

      const response = await axios.get(
        `http://api.lore-stone.com/api/volumes/parent/${id}`
      );

      setVolume(response.data.volume); // Store the retrieved volume data
    } catch (error) {
      console.error("Error fetching volume:", error);
    }
  };

  useEffect(() => {


  
      fetchVolume();
    const fetchUserDetailsFromToken = () => {
      try {
        // Retrieve token from localStorage
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found");
          return;
        }

        // Decode the token payload
        const tokenPayload = JSON.parse(atob(token.split(".")[1]));

        // Extract username and avatar from the token payload
        setUsername(tokenPayload.username);
        setAvatar(tokenPayload.avatar);
      } catch (err) {
        console.error("Error decoding token:", err);
      }
    };

    fetchUserDetailsFromToken();

    const fetchDoc = async () => {
      try {
        const response = await axios.get(
          `http://api.lore-stone.com/api/docs/${id}`
        );
        setDocname(response.data.docname);
      
        setDoccover(response.data.cover);
        document.title = response.data.docname;
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (id) {
      fetchDoc();
    }
  }, [id]);
  useEffect(() => {
    const updateWordCount = () => {
      const { totalWordCount, selectedWordCount } = calculateWordCount();
      setWordCount({ total: totalWordCount, selected: selectedWordCount });
    };

    // Set an interval to check for word count updates
    const interval = setInterval(updateWordCount, 200); // Update every 200ms

    return () => clearInterval(interval); // Cleanup on unmount
  }, [editorRef]);
  useEffect(() => {
    if (volume?.content && editorRef.current) {
      editorRef.current.innerHTML = volume.content;
      generateOutline(editorRef.current); // Generate the outline after loading the content
    }
  }, [volume]);
  


  useEffect(() => {
    console.log("Character Info Updated:", characterinfo);
  }, [characterinfo]);
  
  const handleInput = async (event) => {
    const inputHTML = event.target.innerHTML; // Get the full HTML content

    clearTimeout(handleInput.timeout);

    handleInput.timeout = setTimeout(async () => {
      let updatedHTML = inputHTML;

      // Split the content by spaces to process it word by word
      const words = updatedHTML.split(" ");
      for (let i = 0; i < words.length; i++) {
        // Capitalize the first letter of the document
        if (i === 0) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }

        // Capitalize the first letter after punctuation
        if (i > 0 && [".", "!", "?"].includes(words[i - 1].slice(-1))) {
          words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }

        // Capitalize standalone "i"
        if (words[i] === "i") {
          words[i] = "I";
        }
      }

      // Reconstruct the updated HTML content
      updatedHTML = words.join(" ");

      // Update the contentEditable div only if changes occurred
      if (updatedHTML !== inputHTML) {
        event.target.innerHTML = updatedHTML;

        // Preserve caret position
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(event.target);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      // Generate the outline
      generateOutline(event.target);
      setContent(updatedHTML); // Update state
      console.log("Content updated:", updatedHTML);

      // Send updated HTML content to the backend
      try {
        const response = await axios.post(
          `http://api.lore-stone.com/api/volumes/${id}/content`,
          { content: updatedHTML }
        );
        console.log("Content saved:", response.data);
      } catch (error) {
        console.error("Error saving content:", error);
      }
    }, 100); // Delay processing by 100ms
  };
  const calculateWordCount = () => {
    // Get the content of the editor
    const editorContent = editorRef.current?.innerText || "";

    // Calculate total word count
    const totalWordCount = editorContent
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    // Calculate selected word count
    let selectedWordCount = 0;
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0); // Get the current selection range
      const container = range.commonAncestorContainer;

      // Ensure the selection is inside the contentEditable div
      if (editorRef.current.contains(container)) {
        const selectedText = selection.toString();
        selectedWordCount = selectedText
          .trim()
          .split(/\s+/)
          .filter((word) => word.length > 0).length;
      }
    }

    return { totalWordCount, selectedWordCount };
  };
  const handleCharacterClick = (character, index) => {
    setcharacterinfo(character);
    setshowcharacterinfoindexpopup(index);
    setshowcharacterinfopopup(true);
   
  };
  const generateOutline = (contentEditable) => {
    const headers = contentEditable.querySelectorAll("h1, h2, h3, h4, h5, h6"); // Find all headers
    const outlineContainer = document.querySelector(".houtlinercontainer"); // Get the outline container
    outlineContainer.innerHTML = ""; // Clear existing outline

    headers.forEach((header, index) => {
      // Add an ID to each header for hotlinking
      if (!header.id) {
        header.id = `header-${index}`;
      }

      // Create a link to the header
      const link = document.createElement("a");
      link.href = `#${header.id}`;
      link.textContent = header.textContent; // Use the header text
      link.className = "houtliner-link"; // Add a class for styling

      // Add the link to the outline container
      const listItem = document.createElement("div");
      listItem.appendChild(link);
      outlineContainer.appendChild(listItem);
    });
  };

  const handleCommand = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const addLink = () => {
    const url = prompt("Enter the URL:");
    if (url) {
      handleCommand("createLink", url);
    }
  };

  const addImage = () => {
    const url = prompt("Enter the image URL:");
    if (url) {
      handleCommand("insertImage", url);
    }
  };

  const toggleFontColorPicker = () => {
    setShowFontColorPicker(!showFontColorPicker);
    setShowHighlightColorPicker(false);
  };

  const toggleHighlightColorPicker = () => {
    setShowHighlightColorPicker(!showHighlightColorPicker);
    setShowFontColorPicker(false);
  };

  const applyFontColor = (color) => {
    handleCommand("foreColor", color);
    setShowFontColorPicker(false);
  };

  const applyHighlightColor = (color) => {
    handleCommand("hiliteColor", color);
    setShowHighlightColorPicker(false);
  };
  const [isoutlinerSidebarVisible, setoutlinerSidebarVisible] = useState(false);
  const [ischaracterSidebarVisible, setcharacterSidebarVisible] =
    useState(false);
  const [isplacesSidebarVisible, setplacesSidebarVisible] = useState(false);
  const [isnotesSidebarVisible, setnotesSidebarVisible] = useState(false);
  const toggleoutlinerSidebar = () => {
    setoutlinerSidebarVisible(!isoutlinerSidebarVisible); // Toggle the sidebar visibility
  };
  const togglecharacterSidebar = () => {
    setcharacterSidebarVisible(!ischaracterSidebarVisible); // Toggle the sidebar visibility
  };
  const toggleplacesSidebar = () => {
    setplacesSidebarVisible(!isplacesSidebarVisible); // Toggle the sidebar visibility
  };
  const togglenotesSidebar = () => {
    setnotesSidebarVisible(!isnotesSidebarVisible); // Toggle the sidebar visibility
  };

  const deleteCharacter = async (index) => {
    try {
      const response = await axios.delete(
        `http://api.lore-stone.com/api/volumes/${id}/characters/${index}`
      );
      console.log(response.data.message); // Log success message
      await fetchVolume(); // Refresh the volume data
    } catch (error) {
      console.error('Error deleting character:', error);
    }
  };

  const deletePlace = async (index) => {
    try {
      const response = await axios.delete(
        `http://api.lore-stone.com/api/volumes/${id}/places/${index}`
      );
      console.log(response.data.message); // Log success message
      await fetchVolume(); // Refresh the volume data
    } catch (error) {
      console.error('Error deleting place:', error);
    }
  };
  const deleteNote = async (index) => {
    try {
      const response = await axios.delete(
        `http://api.lore-stone.com/api/volumes/${id}/notes/${index}`
      );
      console.log(response.data.message); // Log success message
      await fetchVolume(); // Refresh the volume data
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handlePlaceClick = (place, index) => {
    setplaceinfo(place); // Set the clicked place information
    setshowplacesinfoindexpopup(index); // Set the index of the clicked place
    setshowplacesinfopopup(true); // Show the place popup
  };
  
  const handleNoteClick = (note, index) => {
    setnoteinfo(note); // Set the clicked note information
    setshownotesinfoindexpopup(index); // Set the index of the clicked note
    setshownotesinfopopup(true); // Show the note popup
  };

  return (
    <div className="docwrapper">
      <Docminiheader docname={docname || "Loading..."} doccover={doccover || ""}/>

      <div className="maindoccontent">
        <div className="doctoolbarwrapper">
          <div className="docspacer">
            <div className="docstatus">
              <div>
                <h6>Online</h6>
              </div>
              <div className="avatars">
                <img
                  src={`http://api.lore-stone.com${avatar}`}
                  alt="avatar"
                  className="avatar"
                />
              </div>
            </div>
            <div className="docsidenav">
            <button className="docsidebuttonL" onClick={toggleoutlinerSidebar}>
              <Description />
            </button>
            <button className="docsidebuttonL" onClick={togglecharacterSidebar}>
              <Person />
            </button>
            <button className="docsidebuttonL" onClick={toggleplacesSidebar}>
              <Place />
            </button>
            <button className="docsidebuttonL" onClick={togglenotesSidebar}>
              <Notes />
            </button>
          </div>
          </div>
          
          <div className="doctooolbar">
            {/* Undo/Redo */}
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("undo")}
            >
              <Undo />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("redo")}
            >
              <Redo />
            </button>

            {/* Formatting */}
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("bold")}
            >
              <FormatBold />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("italic")}
            >
              <FormatItalic />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("underline")}
            >
              <FormatUnderlined />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("strikethrough")}
            >
              <StrikethroughS />
            </button>

            {/* Alignment */}
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("justifyLeft")}
            >
              <FormatAlignLeft />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("justifyCenter")}
            >
              <FormatAlignCenter />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("justifyRight")}
            >
              <FormatAlignRight />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("justifyFull")}
            >
              <FormatAlignJustify />
            </button>

            {/* Lists */}
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("insertUnorderedList")}
            >
              <FormatListBulleted />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("insertOrderedList")}
            >
              <FormatListNumbered />
            </button>

            {/* Links */}
            <button className="doceditorbutton" onClick={addLink}>
              <Link />
            </button>
            <button
              className="doceditorbutton"
              onClick={() => handleCommand("unlink")}
            >
              <LinkOff />
            </button>

            {/* Images */}
            <button className="doceditorbutton" onClick={addImage}>
              <Image />
            </button>
            {/* Headers */}
            <select
              className="doceditorselect"
              onChange={(e) => handleCommand("formatBlock", e.target.value)}
            >
              <option value="p">Normal</option>
              <option value="h1">Heading 1</option>
              <option value="h2">Heading 2</option>
              <option value="h3">Heading 3</option>
              <option value="h4">Heading 4</option>
              <option value="h5">Heading 5</option>
              <option value="h6">Heading 6</option>
            </select>

            {/* Font Family */}
            <select
              className="doceditorselect"
              onChange={(e) => handleCommand("fontName", e.target.value)}
            >
              <option value="Arial">Arial</option>
              <option value="Times New Roman">Times New Roman</option>
              <option value="Courier New">Courier New</option>
              <option value="Verdana">Verdana</option>
            </select>

            <select
  className="doceditorselect"
  onChange={(e) => handleCommand('fontSize', e.target.value)}
>
  <option value="3">Default</option> {/* Default browser font size */}
  <option value="1">Extra Small</option>
  <option value="2">Small</option>
  <option value="4">Medium</option>
  <option value="5">Large</option>
  <option value="6">Extra Large</option>
  <option value="7">Huge</option>
</select>

            {/* Font and Highlight Colors */}
            <button className="doceditorbutton" onClick={toggleFontColorPicker}>
              <FormatColorText />
            </button>
            {showFontColorPicker && (
              <div className="color-picker">
                <button
                  onClick={() => applyFontColor("red")}
                  style={{ background: "red" }}
                ></button>
                <button
                  onClick={() => applyFontColor("blue")}
                  style={{ background: "blue" }}
                ></button>
                <button
                  onClick={() => applyFontColor("green")}
                  style={{ background: "green" }}
                ></button>
              </div>
            )}

            <button
              className="doceditorbutton"
              onClick={toggleHighlightColorPicker}
            >
              <Highlight />
            </button>
            {showHighlightColorPicker && (
              <div className="color-picker">
                <button
                  onClick={() => applyHighlightColor("yellow")}
                  style={{ background: "yellow" }}
                ></button>
                <button
                  onClick={() => applyHighlightColor("lightblue")}
                  style={{ background: "lightblue" }}
                ></button>
                <button
                  onClick={() => applyHighlightColor("lightgreen")}
                  style={{ background: "lightgreen" }}
                ></button>
              </div>
            )}
          </div>
          <div className="docspacer"></div>
        </div>
        <div className="docsidewrapper">
            <div className={"docspacerinner"}>

            </div>
          <div
            className="volumecontent"
            contentEditable={true}
            ref={editorRef}
            onInput={handleInput}
          ></div>
          <div className="doccomments">
            <Comment avatar={`http://api.lore-stone.com${avatar}`} username={username} commentText={"test"} />
          </div>
        </div>
      </div>
      <div className="wordcountwrapper">
        <div className="wordcount">
          {" "}
          <p>
            Word Count: {wordCount.selected} / {wordCount.total}{" "}
          </p>
        </div>
      </div>
      <div
        className={`hsidebarwrapperouter ${
          isoutlinerSidebarVisible ? "active" : ""
        }`}
      >
        <div className="hsidebar">
          <div className="hsidebarWrapper">
            <div className="hsidebarclose" onClick={toggleoutlinerSidebar}>
              X
            </div>
            <h2>Outliner</h2>

            <div className="houtlinercontainer"></div>
          </div>
        </div>
      </div>

      <div
        className={`csidebarwrapperouter ${
          ischaracterSidebarVisible ? "active" : ""
        }`}
      >
        <div className="csidebar">
          <div className="csidebarWrapper">
            <div className="csidebarclose" onClick={togglecharacterSidebar}>
              X
            </div>
            <h2>Character</h2>
          
            <button className="infosidebarbutton" onClick={() => setshowcharacterpopup(true)}>Add Character</button>
            
            <hr></hr>
            {volume && volume.characters && volume.characters.length > 0 ? (
  volume.characters.map((character, index) => (
    <div className="infosidebarbutton" key={index}>
      {/* Clickable Wrapper for Character Image and Name */}
      <div
        className="character-wrapper"
        onClick={() => handleCharacterClick(character, index)}
      >
        {character.image ? (
          <img
            className="characterimage"
            src={`http://api.lore-stone.com${character.image}`}
            alt={character.name || "Character"}
          />
        ) : null}
        <span>{character.name || "Unnamed Character"}</span>
      </div>

     <div    className="delete-button">
      <DeleteIcon
     
        onClick={() => deleteCharacter(index)}
      />
      </div>
       
    </div>
  ))
) : (
  <p>No characters available</p>
)}

          </div>
        </div>
      </div>

      <div
        className={`psidebarwrapperouter ${
          isplacesSidebarVisible ? "active" : ""
        }`}
      >
        <div className="psidebar">
          <div className="psidebarWrapper">
            <div className="psidebarclose" onClick={toggleplacesSidebar}>
              X
            </div>
            <h2>Places</h2>
           
            <button className="infosidebarbutton" onClick={() => setshowplacespopup(true)}>Add Place</button>
            <hr></hr>

            {volume && volume.places && volume.places.length > 0 ? (
  volume.places.map((place, index) => (
    <div className="infosidebarbutton" key={index}>
      {/* Clickable Wrapper for Place Title */}
      <div
        className="character-wrapper"
        onClick={() => handlePlaceClick(place, index)}
      >
        <span>{place.title || "Unnamed Place"}</span>
      </div>

      {/* Delete Button */}
      <div className="delete-button">
        <DeleteIcon onClick={() => deletePlace(index)} />
      </div>
    </div>
  ))
) : (
  <p>No places available</p>
)}


          </div>
        </div>
      </div>

      <div
        className={`nsidebarwrapperouter ${
          isnotesSidebarVisible ? "active" : ""
        }`}
      >
        <div className="nsidebar">
          <div className="nsidebarWrapper">
            <div className="nsidebarclose" onClick={togglenotesSidebar}>
              X
            </div>
            <h2>Notes</h2>
          
            <button className="infosidebarbutton" onClick={() => setshownotespopup(true)}>Add Note</button>
            <hr></hr>
            {volume && volume.notes && volume.notes.length > 0 ? (
  volume.notes.map((note, index) => (
    <div className="infosidebarbutton" key={index}>
      {/* Clickable Wrapper for Note Title */}
      <div
        className="character-wrapper"
        onClick={() => handleNoteClick(note, index)}
      >
        <span>{note.title || "Unnamed Note"}</span>
      </div>

      {/* Delete Button */}
      <div className="delete-button">
        <DeleteIcon onClick={() => deleteNote(index)} />
      </div>
    </div>
  ))
) : (
  <p>No notes available</p>
)}


          </div>
        </div>
      </div>
      {showcharacterpopup && (
      <CreateCharacterPopup setIsActive = {setshowcharacterpopup} refresh={fetchVolume}/>
      )}
       {showplacespopup && (
      <CreatePlacesPopup setIsActive = {setshowplacespopup} refresh = {fetchVolume}/>
      )}
       {shownotespopup && (
      <CreateNotesPopup setIsActive = {setshownotespopup} refresh = {fetchVolume}/>
      )}
      {showcharacterinfopopup &&(
        <CharacterPopup refresh = {fetchVolume} setIsActive = {setshowcharacterinfopopup} characterData={characterinfo} indexId = {characterinfoindexpopup}/>
      )}
 {showplacesinfopopup &&(
        <PlacesPopup refresh = {fetchVolume} setIsActive = {setshowplacesinfopopup} placeData={placeinfo} indexId = {placesinfoindexpopup}/>
      )}
 {shownotesinfopopup &&(
        <NotePopup refresh = {fetchVolume} setIsActive = {setshownotesinfopopup} noteData={noteinfo} indexId = {notesinfoindexpopup}/>
      )}
    </div>
  );
}

export default Doc;
